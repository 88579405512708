import React from "react";
import AboutCtaImage from "../images/about-cta.svg"
import {Link} from "gatsby"

const AboutCta = (props) => {
  const hasImage = props.hasImage ? 'about-cta--has-image' : '';
  const hideTitle = !props.hideTitle;
  const renderImage = () => {
    return (
        <div className="about-cta__image-wrapper">
          <div className="about-cta__image">
            <img src={AboutCtaImage} alt="" className={`img-fluid`}/>
          </div>
        </div>
    )
  }
  const renderTitle = () => {
    return (
      <div className="about-cta__title">About</div>
    )
  }

  const renderEllipse = () => {
    return (
      <div className="about-cta__ellipse"></div>
    )
  }

  const renderDescription = () => {
    return (
      <div className="about-cta__description">
        Creativity is built into our DNA. We believe that technology can be a means of enhancing and expanding on our innate human qualities of imagination, innovation, social connection, and productivity. By finding the right balance with technology, we can empower humans to have more freedom, choice, or time to achieve their full potential.
      </div>
    )
  }
  const renderButton = () => {
    return (
      <div className="about-cta__button-wrapper">
        <Link className={`button button--green-ghost`} to={`/about`}>Learn more</Link>
      </div>
    )
  }
  return (
    <section className={`about-cta ` + hasImage}>
      <div className="container">
        <div className="about-cta__wrapper">
          <div className="about-cta__text-wrapper">
            {hideTitle ? renderTitle() : renderEllipse()}
            <div className="about-cta__section-title">We believe in building technology for
              <span className="elf-green"> better human experiences </span>
            </div>
            {hideTitle ? renderButton() : renderDescription()}
          </div>
          {props.hasImage ? renderImage() : ''}
        </div>
      </div>
    </section>
  )
}

export default AboutCta;
