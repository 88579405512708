import React from "react";

import TipsImageOne from "../images/tips-images/tips-one.png"
import TipsImageTwo from "../images/tips-images/tips-two.png"
import TipsImageThree from "../images/tips-images/tips-three.png"
import TipsImageFour from "../images/tips-images/tips-four.png"

const TipsContent = (props) => {
  const data = props.data;
  const renderText = (description) => {
    return {__html: description}
  }
  const getTipsImage = (index) => {
    if(index === 0){
      return TipsImageOne
    }
    if(index === 1){
      return TipsImageTwo
    }
    if(index === 2){
      return TipsImageThree
    }
    if(index === 3){
      return TipsImageFour
    }

  }
  const removeTags = (str) => {
    if ((str===null) || (str===''))
      return false;
    else
      str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
  }

  /*Generates Section Title and Image*/
  const renderTitleImage = (title, image, type, position) => {
    const placement = position === 'right' ? 'tips-content__title-image--place-right' : ''
    return (
      <div className={"tips-content__title-image " + placement}>
        <div className="tips-content__section-title" dangerouslySetInnerHTML={renderText(title)}/>
        <div className={`tips-content__section-image  tips-content__section-image--` + type}>
          <img className={`tips-content__image`} src={image} alt={title}/>
        </div>
      </div>
    )
  }
  /*Generates Section Title and Image*/
  const renderTipsList = (list, position) => {
    const placement = position === 'right' ? 'tips-content__tips-list--place-left' : ''

    return (
      <div className={" tips-content__tips-list-wrapper " + placement}>
        {
          list.map((listItem, index) => (

            <div className={`tips-content__tips-list-container`} key={index}>
              <div className={"tips-content__tips-list-icon"}>
                <span className={"tips-icon icon icon-" + listItem['icon']}></span>
              </div>
              <div className={"tips-content__tips-list-items"}>
                <div className={"tips-content__tips-list-title"} dangerouslySetInnerHTML={renderText(listItem['title'])}/>
                <div className="tips-content__tips-list-description" dangerouslySetInnerHTML={renderText(listItem['description'])}/>
              </div>
            </div>

          ))
        }
      </div>
    )
  }
  /*Render final block*/
  return (
    <section className="tips-content">
      {
        data.nodes.map((items, index) => (

          <div key={items['id']} id={removeTags(items['title']).replace(/\s+/g, '-').toLowerCase()} className={"tips-content__wrapper"}>
            {renderTitleImage(items['title'], getTipsImage(index), items['type'], items['titlePosition'])}
            {renderTipsList(items['textDescription'], items['titlePosition'])}
          </div>
        ))
      }

    </section>
  )
}
export default TipsContent;
