import React from "react";
import Lottie from 'lottie-react-web'


const InteriorHeader = (props) => {
  const title = props.title;
  const DesktopImage = props.desktopImage;
  const MobileImage = props.mobileImage;
  const pageLocation = props.pageLocation;
  const animation = props.animation

  return (
    <section className={`interior-hero ` + pageLocation + "__interior-hero " + pageLocation}>
      <div className="container">
        {
          typeof title !== 'undefined' ?
            <h1 className={"interior-hero__title page-title " + pageLocation + "__title"}>{title}</h1> :
            ''
        }
        <div className={"interior-hero__image-wrapper " + pageLocation + "__image-wrapper"}>
          { DesktopImage ?
            <picture>
              <source media="(max-width: 767px)" srcSet={MobileImage}/>
              <source media="(min-width: 768px)" srcSet={DesktopImage}/>
              <img src={DesktopImage} alt={title}/>
            </picture>
          : ""
          }
          { animation ?
            <Lottie
              options={{
                animationData: animation
              }}
            />
          : ""
          }
        </div>
      </div>
    </section>
  )
}

export default InteriorHeader
