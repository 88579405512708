import React from "react"
import InteriorHeader from "../components/interior-header";
import SEO from "../components/seo";
import Layout from "../components/layout"
import AboutCta from "../components/about-cta";
import TipsContent from "../components/tipsContent";
import animation from '../animations/tips-hero.json';
import {graphql, useStaticQuery} from "gatsby";

const Tips = () => {
  const data =  useStaticQuery(graphql`
  {
    allTipsContentJson {
      nodes {
        id
        title
        titlePosition
        type
        textDescription {
          icon
          title
          description
        }
      }
    }
  }
  `)
  return (
    <Layout>
      <SEO title={`Tips`} description="Get our tips on how you can minimize distractions, create positive habits and routines, and connect and create with others"/>
      <InteriorHeader title={`Tips`} pageLocation={'tips-interior-banner'} animation={animation}/>
      <TipsContent data={data.allTipsContentJson}/>
      <AboutCta hasImage={false}/>
    </Layout>
  )
}


export default Tips;
